/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */

import Vue from "vue";
import dayjs from "dayjs";
import "dayjs/locale/es";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(weekday);

Vue.filter("toCurrency", value => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "CLP",
    currencyDisplay: "code",
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter("toCurrentDate", currentDate => {
  if (currentDate !== null) {
    return `${dayjs(currentDate).format("HH:mm")} hrs, ${dayjs(currentDate).format("DD MMMM YYYY")}`;
  }

  return "Por determinar";
});

Vue.filter("getRiskProfile", score => {
  const riskProfiles = [
    {
      type: "low",
      scoreRange: [6, 8],
      averageScore: 7,
      color: "#1AB9EC",
    },
    {
      type: "belowAverage",
      scoreRange: [9, 11],
      averageScore: 10,
      color: "#4E5FF1",
    },
    {
      type: "average",
      scoreRange: [12, 14],
      averageScore: 13,
      color: "#00AA9D",
    },
    {
      type: "aboveAverage",
      scoreRange: [15, 17],
      averageScore: 16,
      color: "#FF0079",
    },
    {
      type: "high",
      scoreRange: [18, 24],
      averageScore: 21,
      color: "#AF0053",
    },
  ];
  const riskProfile = riskProfiles
    .find(profile => score >= profile.scoreRange[0] &&
      score <= profile.scoreRange[[profile.scoreRange.length - 1]]);
  if (typeof riskProfile !== "undefined") {
    return riskProfile;
  }
  return {
    type: "none",
    averageScore: null,
    scoreRange: [],
    color: "#FF0079",
  };
});

Vue.filter("currency", (value, currency) => {
  if (typeof value !== "number") {
    return value;
  }

  const minimumFractionDigits = currency === "CLP" ? 0 : 2;

  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency,
    currencyDisplay: "code",
    minimumFractionDigits,
    useGrouping: true,
  });
  return formatter.format(value);
});

Vue.filter("toQuotaNumber", value => {
  if (typeof value !== "number") {
    return value;
  }

  const digits = value === 0 ? 0 : 4;

  const formatter = new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    minimumIntegerDigits: 1,
  });
  return formatter.format(Math.round(value * 10000) / 10000);
});

Vue.filter("currencyNoSymbol", (value, maximumFractionDigits = 0) => {
  if (typeof value !== "number") {
    return value;
  }

  const formatter = new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits,
  });
  return formatter.format(value);
});

Vue.filter("toPercentage", (value, minimumFractionDigits = 0) => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("es-ES", {
    style: "percent",
    minimumFractionDigits,
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter("toDecimalPercentage", (value, decimals = 0) => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: decimals,
  });
  return formatter.format(value);
});

Vue.filter("toNumber", value => {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumSignificantDigits: 2,
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
});

Vue.filter("formatRut", value => {
  if (value) {
    const rut = value.toString().split("");
    const rutNoDV = rut.filter(word => word !== "-" && word !== ".");
    if (rutNoDV.length < 8 || rutNoDV.length > 9) {
      return false;
    }
    if (rutNoDV.length === 9) {
      rutNoDV.splice(2, 0, ".");
      rutNoDV.splice(6, 0, ".");
      rutNoDV.splice(10, 0, "-");
    } else if (rutNoDV.length === 8) {
      rutNoDV.splice(1, 0, ".");
      rutNoDV.splice(5, 0, ".");
      rutNoDV.splice(9, 0, "-");
    }

    return rutNoDV.join("");
  }
  return "";
});

Vue.filter("removeDots", value => {
  if (value) {
    return value.replace(/[.\s]/g, "");
  }
  return value;
});

Vue.filter("formatDate", value => {
  if (value) {
    return dayjs(value, { locale: "es" }).format("D MMMM YYYY");
  }
  return "";
});

Vue.filter("toDate", (value, language = "es") => {
  if (value && language === "es") {
    return dayjs(value, { locale: language }).format("DD MMMM YYYY");
  }
  if (value && language === "en") {
    return dayjs(value, { locale: language }).format("MMMM D, YYYY");
  }
  return "";
});

Vue.filter("formatDatetime", value => {
  if (value) {
    return dayjs(value, { locale: "es" }).format("D MMMM YYYY, h:mm A");
  }
  return "";
});

Vue.filter("getClosingDay", date => {
  if (date) {
    return dayjs(date, { locale: "es" }).format("DD/MM/YY");
  }

  return "";
});

Vue.filter("getMonthAndDay", date => {
  if (!date) return "";
  return dayjs.utc(date, { locale: "es" }).format("D [de] MMMM");
});

Vue.filter("addFundColor", value => {
  const fundColorList = {
    9118: "#4E5FF1",
    9362: "#00AA9D",
    9395: "#00AA9D",
    9725: "#FF0079",
    9937: "#832FED",
    9958: "#135D75",
    9994: "#C50076",
    10275: "#CA9328",
    10348: "#1EC26A",
    10431: "#C50076",
    7200: "#2A7333",
    9363: "#C2651E",
    9102: "#7B7FCA",
    total: "steelblue",
    others: "grey",
  };
  return fundColorList[value] || fundColorList.others;
});

Vue.filter("addFundAcronym", value => {
  const fundAcronyms = {
    9118: "MA",
    9362: "FMCAC",
    9395: "CHI",
    9725: "SF",
    9937: "MT",
    9958: "DP",
    9994: "LQ",
    10074: "CONS",
    10275: "EQUI",
    10348: "E",
    10431: "FMCL",
    7200: "CDPFI",
    9363: "CGC",
    9102: "CLC",
    others: "",
  };

  return fundAcronyms[value] || fundAcronyms.others;
});

Vue.filter("fundShortName", value => {
  const fundOptions = {
    9118: "Multiaxis",
    9362: "Acciones Chilenas",
    9395: "All Cap Chile",
    9725: "Silicon Fund",
    9937: "Medtech",
    9958: "Deuda Privada USA",
    9994: "Liquidez",
    10074: "Conservador",
    10275: "Equilibrio",
    10348: "E-Fund",
    10431: "Liquidez",
    7200: "Deuda Plus",
    9363: "Global Credit",
    9102: "Latam Corporate Debt",
  };
  if (!value) {
    return fundOptions;
  }
  return fundOptions[value];
});

Vue.filter("fundFullName", value => {
  const fundOptions = {
    9118: "Compass DVA Multiaxis",
    9362: "Fondo Mutuo Compass Acciones Chilenas",
    9395: "Compass DVA All Cap Chile",
    9725: "Compass DVA Silicon Fund",
    9937: "Compass DVA Medtech",
    9958: "Compass DVA Deuda Privada USA",
    9994: "Compass Liquidez FM",
    10275: "Compass Equilibrio",
    10348: "Compass DVA E-Fund",
    10431: "Fondo Mutuo Compass Liquidez",
    7200: "Compass Deuda Plus",
    9363: "Compass Global Credit",
  };
  if (!value) {
    return fundOptions;
  }
  return fundOptions[value];
});

Vue.filter("supressDots", str => {
  if (str.toString().includes("@")) return str;
  return str.toString().replaceAll(".", "");
});

Vue.filter("customerName", fullName => {
  if (!fullName) return "";
  const firstName = fullName.split(" ")[0];
  return firstName;
});

Vue.filter("customerInitials", fullName => {
  if (!fullName) return "";
  const words = fullName.toString().split(" ");
  let initials = "";
  if (words[0]) {
    initials += words[0].toString().substring(0, 1);
  }
  if (words[1]) {
    initials += words[1].toString().substring(0, 1);
  }
  return initials;
});

Vue.filter("getFullName", profile => {
  if (typeof profile !== "object") return profile;

  const lastName = profile?.lastName?.toString();

  if (!lastName) return profile?.name;

  const firstName = profile?.name.toString().split(" ").slice(0, 1).join(" ");

  if (lastName?.length < 20) {
    return firstName.concat(" ", lastName);
  }
  return firstName.concat(" ", profile?.lastName?.split(" ").slice(0, 1).join(" "));
});

Vue.filter("formatBalanceAmount", (amount, balanceType) => {
  if (typeof amount !== "number") {
    return amount;
  }
  let symbol = "";

  const formatter = new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: 0,
  });
  const formattedAmount = formatter.format(Math.abs(amount));
  if (["RESULT", "CURRENT_RESULT", "WITHDRAWN_RESULT"].includes(balanceType)) {
    // For negative results
    if (amount < 0) {
      symbol = "-";
    } else symbol = "+";
  }
  if (["WITHDRAWALS", "DISTRIBUTIONS"].includes(balanceType)) {
    // This is to not prefix minus sign if the value is zero
    symbol = "-";
  }
  if (["DISTRIBUTION_REINVESTMENTS", "DEPOSITS", "TOTAL_DEPOSITS"].includes(balanceType)) {
    // This is to not prefix minus sign if the value is zero
    symbol = "+";
  }

  return `${symbol}$${formattedAmount}`;
});

Vue.filter("removeExtension", fileName => {
  if (!fileName) return fileName;

  return fileName.substring(0, fileName.lastIndexOf(".")) || fileName;
});

Vue.filter("yearsOld", date => {
  if (!date) return date;
  const currentDate = dayjs();
  return currentDate.diff(dayjs(date), "year");
});

Vue.filter("daysTo", date => {
  if (!date) return date;
  const currentDate = dayjs();
  return dayjs(date).diff(currentDate, "day");
});

Vue.filter("getDayAndMonth", date => {
  if (!date) return "";
  return dayjs.utc(date).format("dddd D [de] MMMM");
});
